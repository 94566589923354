import axios from '@/plugins/http.js';
class myCourseAPI {
  getCourseList(params) {
    // 课程列表
    return axios.get(`/course-list?${params}`);
  }
  getBannerCourseList(params) {
    // 课程列表
    return axios.get(`/banner/course-list?${params}`)//轮播图关联所有的课程
  }
  addCourse(params) {
    // 新建课程
    return axios.post(`/course`, params);
  }
  updateCourse(params) {
    // 编辑课程
    return axios.put(`/course`, params);
  }
  getCourseDetail(id) {
    // 课程详情
    return axios.get(`/course/${id}`);
  }
  delCourse(id) {
    // 删除课程
    return axios.delete(`/course/${id}`);
  }
  getCourseMenuList(id) {
    // 课程菜单列表
    return axios.get(`/course/${id}/menu-list`);
  }
  getAddCourseMenu(id, params) {
    // 课程菜单添加
    return axios.post(`/course/${id}/menu`, params);
  }
  getEditCourseMenu(id, params) {
    // 课程菜单编辑
    return axios.put(`/course/${id}/menu`, params);
  }
  getDeleteCourseMenu(id, menuId) {
    // 课程菜单删除
    return axios.delete(`/course/${id}/menu/${menuId}`);
  }
  getVideoList(id, menuId, params) {
    // 课程菜单视频列表
    return axios.get(`/course/${id}/menu/${menuId}/file-list?${params}`);
  }
  getDeleteFile(id, fileId) {
    // 课程菜单课件删除
    return axios.delete(`/course/${id}/file/${fileId}`);
  }
  getDeleteExam(id, fileId) {
    // 课程菜单考试删除
    return axios.delete(`/course/${id}/exam/${fileId}`);
  }
  getChapter(id, params) {
    // 章节目录添加
    return axios.post(`/course/${id}/chapter-list`, params);
  }
  getChapterList(id) {
    // 章节目录详情/列表（树形结构）
    return axios.get(`/course/${id}/chapter-list`);
  }
  getSignRecord(params) {
    //添加签到签退
    return axios.post(`/sign-record`, params);
  }
  getSignList(params) {
    //签到签退列表
    return axios.get(`/sign-record-list?${params}`);
  }
  getSignExport(url, params, responseType) {
    //签到签退导出
    return axios.get(`${url}?${params}`, responseType);
  }
  getEvaluation(params) {
    //添加评论
    return axios.post(`/evaluation`, params);
  }
  getEvaluationList(params) {
    //评论数据列表
    return axios.get(`/evaluation-list?${params}`);
  }
  getEvaluationExport(params, responseType) {
    //评论数据导出
    return axios.get(`/evaluation.xlsx?${params}`, responseType);
  }
  getStudyLogList(id, params) {
    //观众流水列表
    return axios.get(`/course/${id}/study-log-list?${params}`);
  }
  getStudyLogExport(id, params, responseType) {
    //观众流水导出
    return axios.get(`/course/${id}/study-log.xlsx?${params}`, responseType);
  }
  getCollect(id, params) {
    //添加收藏
    return axios.post(`/user/${id}/collect`, params);
  }
  getDeleteCollect(id, params) {
    //取消收藏
    return axios.delete(`/user/${id}/collect`, params);
  }
  getDeleteSurvey(id, surveyId) {
    // 课程菜单问卷删除
    return axios.delete(`/course/${id}/survey/${surveyId}`);
  }
  putCourse(params) {//点赞课程编辑
    return axios.put(`/course`,params)
  }
  getAuditLogList(params) {
    //获取审核记录
    return axios.get(`/audit-log-list?${params}`);
  }
}
export default new myCourseAPI();
