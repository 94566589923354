<template>
  <div class="container">
    <div class="hh_top_wrap">
      <el-steps :active="active" align-center>
        <el-step title="选择直播课程"></el-step>
        <el-step title="选择回放视频"></el-step>
      </el-steps>
    </div>
    <div class="hh_center_wrap" v-show="isLive">
      <el-form class="form">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="开始时间">
              <el-date-picker
                clearable
                :picker-options="beginTimeOption"
                v-model="formData.createStartTime"
                placement="bottom-start"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择开始时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束时间">
              <el-date-picker
                clearable
                :picker-options="endTimeOption"
                v-model="formData.createEndTime"
                placement="bottom-start"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择结束时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-input
                v-model="formData.keyword"
                placeholder="请输入关键字"
                maxlength="50"
                clearable
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button class="btn btn_blue" icon="el-icon-search" @click="search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="table table_warp">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click="handleSelectionChange"
        >
          <el-table-column width="50">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="课程名" prop="courseTitle" width="280" />
          <el-table-column label="创建人" prop="creatorName" />
          <el-table-column label="创建时间" prop="createTime" />
        </el-table>
        <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount" @pageChange="pageVal"></pagination>
      </div>
    </div>
    <div class="hh_center_wrap" v-show="isReplay">
      <h1 class="color_blue">{{ title }}</h1>
      <div class="table table_warp">
        <el-table
          ref="multipleTable"
          :data="replayData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelection"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column show-overflow-tooltip label="视频ID" prop="videoId" />
          <el-table-column show-overflow-tooltip label="推流标识" prop="remark" />
          <el-table-column show-overflow-tooltip label="录制时间" prop="recStartTime" />
          <el-table-column label="时长" prop="duration">
           <template slot-scope="scope">{{ formatTime(parseInt(scope.row.duration)) }}</template>
          </el-table-column>
          <el-table-column label="大小" prop="videoSize" >
            <template slot-scope="scope">{{scope.row.videoSize}}M</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <span class="operate color_blue" @click="copyUrl(scope.row)">复制地址</span>
              <!-- <span class="operate color_blue" @click="preview(scope.row)">预览</span> -->
            </template>
          </el-table-column>
          <el-table-column width="220" label="设置回放视频名称" prop="videoName">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.videoName"
                placeholder="请输入回放视频名称"
                maxlength="50"
                show-word-limit
                @change="reName(scope.row.videoName)"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="dialog_submit">
      <el-button v-show="isLive" class="btn btn_blue" style="margin-top: 12px" :disabled="isSelect" @click="next">
        下一步
      </el-button>
      <el-button v-show="isReplay" class="btn btn_blue" style="margin-top: 12px" @click="previousOne">上一步</el-button>
      <el-button
        v-show="isReplay"
        class="btn btn_blue"
        style="margin-top: 12px"
        @click="submit"
      >提交</el-button>
    </div>
    <!-- <video
        v-if="isShow"
        id="videoer"
        controls
        class="hh_video"
        :src="filePath"
        @play="videoIsPlay = true;"
        @pause="videoIsPlay = false;"
        @ended="videoIsPlay = false;"
        >
    </video> -->
    <!-- <Video v-if="isShow" id="videoer" class="video-js vjs-default-skin" controls muted>
      <source
        src="http://1301295327.vod2.myqcloud.com/d5e8c443vodcq1301295327/81fd023c387702299588300448/playlist_eof.m3u8"
        type="application/x-mpegURL"
      />
    </Video> -->
  </div>
</template>
<script>
import API from '@apis/backStageEndAPI/coursewareManagementAPI'
import CommonUtils from '@/utils'
import api from '@/apis/backStageEndAPI/myCourseAPI'
import pagination from '@/components/backStageComponent/pagination';
import {formatTime} from "@assets/js/format";
// import useClipboard from 'vue-clipboard3'
// import videojs from 'video.js'
export default {
  name: 'pullVideo',
  components:{pagination},
  data() {
    return {
      formatTime,
      totalCount: 0,
      isShow: false,
      filePath: '',
      replayData: [],
      isSelect: true,
      courseId: '',
      radio: '',
      replayName: '',
      isReplay: false,
      isLive: true,
      active: 1,
      fileData: [
        {
          duration: '',
          recEndTime: '',
          recStartTime: '',
          remark: '',
          videoId: '',
          videoName: '',
          videoPath: '',
          videoSize: '',
        },
      ],
      formData: {
        courseType: 1, //课程类型：1|直播课，2|章节录播课
        page: 1, //页码
        pageSize: 14, //	分页大小
        createStartTime: '', //创建开始时间
        createEndTime: '', //创建截止时间
        keyword: '', //模糊查询
        labels: '', //标签，多标签逗号拼接
        state: '', //课程状态
        subjectId: '', //学科id
      },
      beginTimeOption: {
        disabledDate: time => {
          let delay = this.formData.createEndTime
          if (delay) {
            return time.getTime() > new Date(delay).getTime() - 8.64e7
          }
        },
      },
      endTimeOption: {
        disabledDate: time => {
          let delay = this.formData.createStartTime
          if (delay) {
            return time.getTime() < new Date(delay).getTime() + 8.64e7
          }
        },
      },
      title: '',
      tableData: [],
      videoElement: '',
    }
  },
  mounted() {
    this.getCourseList()
    this.videoElement = document.getElementsByTagName('video')
  },
  // beforeDestroy() {
  //   if (this.player != null) {
  //     this.player.dispose() // dispose()会直接删除Dom元素
  //   }
  // },
  methods: {
    pageVal(val) { //分页跳转
      this.formData.page = val;
      this.getCourseList()
    },
    reName(val) {
      this.fileData.forEach(ele => {
        ele.videoName = val
      })
    },
    handleSelection(val) {
      this.fileData = val
    },
    handleSelectionChange(val) {

      this.courseId = val.courseId
      this.title = val.courseTitle
      this.isSelect = false
    },
    search() {
      //搜索
      this.formData.page = 1
      this.getCourseList()
    },
    getStreamList() {
      API.getStreamList(this.courseId).then(res => {
        this.replayData = res
      })
    },
    getCourseList() {
      //获取课程列表
      this.formData.createStartTime == null ? (this.formData.createStartTime = '') : this.formData.createStartTime
      this.formData.createEndTime == null ? (this.formData.createEndTime = '') : this.formData.createEndTime
      api.getCourseList(CommonUtils.parseToParams(this.formData)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    copyUrl(val) {
      let msg = val.videoPath
      this.copyPath(msg)
    },
    async copyPath(val) {
      //复制
      const { toClipboard } = useClipboard()
      try {
        await toClipboard(val)
        this.$message.success('复制链接成功')
      } catch (e) {
        console.error(e)
      }
    },
    // preview(item) {
    //   this.isShow = true
    //   // const vid = document.getElementById("videoer");
    //   // vid.play()
    //   // vid.addEventListener('ended', function () { //结束
    //   //    this.isShow = false
    //   // }, false);
    //   // var options = {};
    //   // 播放器初始化
    //   this.player = videojs(
    //     'videoer',
    //     {
    //       bigPlayButton: false,
    //       textTrackDisplay: false,
    //       posterImage: true,
    //       errorDisplay: false,
    //       controlBar: true,
    //       muted: true, //静音模式 、、 解决首次页面加载播放。
    //     },
    //     function () {
    //       this.play() // 自动播放
    //     }
    //   )
    // },
    next() {
      if (this.active++ > 1) {
        this.active = 1
      } else {
        this.isLive = false
        this.isReplay = true
        this.getStreamList()
      }
    },
    previousOne() {
      this.active = 1
      this.radio = ''
      this.isLive = true
      this.isReplay = false
      this.isShow = false
      this.isSelect = true
    },
    submit() {
      const params = this.fileData
      if(params[0].videoId) {
        API.turnVideo(params).then(res => {
          console.log(res)
        })
        this.$emit('showPullOne', false)
      }else {
        this.$message.error('请选择直播课程！')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
.hh_top_wrap {
  margin: 0 auto;
  width: 60%;
}
.hh_center_wrap {
  padding: 15px 0 25px 15px;
  width: 100%;
  background: #f4f9fd;
  h1 {
    padding: 15px 10px;
    text-align: left;
    font-size: 24px;
  }
}
.form {
  padding-top: 10px;
  padding-left: 50px;
}
.table_warp {
  padding-top: 10px;
  width: 1130px;
}
::v-deep .el-form-item__content {
  padding-left: 0;
}
.hh_video {
  width: 500px;
  height: 400px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
